import logo from "../assets/images/logo.svg";
import background from "../assets/images/background.png";
import protocolBackImg from "../assets/images/Orchestrator.png";
import unifiedDesign from "../assets/images/iquantum-cover.jpg";
import metaMask from "../assets/images/metamask-fox.svg";
import fortmatic from "../assets/images/fortmatic.svg";
import authereum from "../assets/images/authereum_shield.svg";
import walletLink from "../assets/images/coinbase-wallet.svg";
import dapper from "../assets/images/dappy.svg";
import walletConnect from "../assets/images/walletconnect-logo.svg";
import robSecord from "../assets/images/charged-iquantumflows-headshot-rob-secord.webp";
import theLao from "../assets/images/the-lao-logo-white.svg";
import coingecko from "../assets/images/coingecko-logo-white.svg";
import longhash from "../assets/images/longhash-ventures-logo-white.svg";
import moonwhale from "../assets/images/moonwhale-ventures-logo-white.svg";
import parafi from "../assets/images/parafi-capital-logo-white.svg";
import ventures from "../assets/images/d64-ventures-logo-white.svg";
import fourRevolution from "../assets/images/four-the-revolution-captial-logo-white.svg";
import au21Capital from "../assets/images/au21-capital-logo-white.svg";
import hashkey from "../assets/images/hashkey-capital-logo-white.svg";

import vijay from "../assets/teams/_vijay.jpeg";
import prakash from "../assets/teams/prakash.jpeg";
import madhu from "../assets/teams/_madhu.jpg";
import gaurav from "../assets/teams/gaurav.jpg";
import kiran from "../assets/teams/kiran.jpeg";
import raghav from "../assets/teams/raghav.jpeg";
import sachin from "../assets/teams/sachin.jpeg";
import vicky from "../assets/teams/vicky.jpeg";
import shani from "../assets/teams/shani.jpg";
import shivam from "../assets/teams/shivam.jpg";
import salman from "../assets/teams/salman.jpeg";
import devi from "../assets/teams/devi.jpg";


const IMG = {
  logo,
  background,
  protocolBackImg,
  unifiedDesign,
  metaMask,
  fortmatic,
  authereum,
  walletLink,
  dapper,
  walletConnect,
  robSecord,
  theLao,
  coingecko,
  longhash,
  moonwhale,
  parafi,
  ventures,
  fourRevolution,
  au21Capital,
  hashkey,
  vijay,
  prakash,
  madhu,
  gaurav,
  kiran,
  raghav,
  sachin,
  vicky,
  shani,
  shivam,
  salman,
  devi
};
export default IMG;
