import React from 'react'

function ColorButton({color,value,icon}) {
    return (
        <button
            style={{
                backgroundColor: color
            }}
            className="back-btn">
            {value}
        </button>
    )
}

export default ColorButton
