import React from "react";
import { Link } from "react-router-dom";
import { footerLink, footerSocialLink } from "../shared/String";

function FooterComponent() {
  return (
    <div className="footer">
      <div className="footer-div">
        <div className="copyright">
          <p className="footer-para">
            Copyright © {new Date().getFullYear()} IQuantum Solutions Private
            Limited. <br /> All rights reserved.
          </p>
          <p className="footer-copyright-link">
            {/* <Link className="footer-copyright-link" to=""> */}
            <span>E-Mail: vijay@iquantumtech.com </span>
            <br />
            <span>Mobile: +9198801 63133</span>
            {/* </Link> */}
          </p>
          {/* <p className="footer-para">Located in India.</p>
          <p className="footer-para">Site design by IQuantum Experience Team</p> */}
        </div>
        {/* <div className="footer-links">
          {footerLink.map((elm, i) => {
            return (
              <Link className="footer-link" to={elm.link}>
                {elm.name}
              </Link>
            );
          })}
        </div> */}
        {/* <div className="footer-icon">
          {footerSocialLink.map((elm, i) => {
            return (
              <Link className="footer-link-social footer-link" to={elm.link}>
                <i className={`${elm.name} fa-2x`}></i>
              </Link>
            );
          })}
        </div> */}
      </div>
    </div>
  );
}

export default FooterComponent;
