import React from "react";
import ColorButton from "../../Component/ColorButton";
import { Link } from "react-router-dom";
import IMG from "../../shared/image";
import AppConstant from "../../shared/AppConstant.json";
import { learnCard, investor, angelInvestors } from "../../shared/String";

function Connect() {
  return (
    <div className="connect">
      {/* <div className="upper-learn">
        <h2 className="protocol-heading">{AppConstant.home.connect.heading}</h2>
        <h5 className="partical-card-second-heading mt-0">
          {AppConstant.home.connect.description}
        </h5>
      </div> */}
      {/* <div className="lower-learn">
        <div className="row justify-content-center">
          {learnCard.map((elm, i) => {
            return (
              <div className="learn-card text-center">
                <Link className="connect-icon" to="">
                  <i class={elm.image}></i>
                </Link>
                <h2 className="protocol-heading protocol-heading-font mt-2 mb-0">
                  {elm.name}
                </h2>
                <h5 className="partical-card-second-heading mt-0">
                  {elm.describe}
                </h5>
              </div>
            );
          })}
        </div>
      </div> */}

      {/* <h2 className="protocol-heading text-center">
        {AppConstant.home.connect.backedBy}
      </h2> */}
      {/* <h2 className="protocol-heading protocol-heading-font">
        {AppConstant.home.connect.investors}
      </h2> */}

      {/* <div className="row">
        {investor.map((elm, i) => {
          return (
            <div className="invester">
              <Link to="">
                <img src={elm} width="35%" alt="" srcSet="" />
              </Link>
            </div>
          );
        })}
      </div> */}
      <h2 className="protocol-heading protocol-heading-font mt-3" >
        {AppConstant.home.connect.angelInvestors}
      </h2>

      {/* <div className="row mb-5">
        {angelInvestors.map((elm, i) => {
          return (
            <div key={i} className="angel-invester">
              <Link to="">
                <img src={IMG.robSecord} width="40%" alt="" srcSet="" />
              </Link>
              <Link to="">
                <h3 className="protocol-heading protocol-heading-font mt-3 mb-0">
                  {elm.name}
                </h3>
              </Link>
              <p className="partical-card-second-heading mt-0">
                {elm.describe}
              </p>
            </div>
          );
        })}
      </div> */}
      <p className="partical-card-second-heading text-center ">
        {AppConstant.home.connect.secondDescription}
      </p>
      <div className="text-center mt-4">
        <ColorButton color="#4700c3" value="Contact Us" icon="" />
      </div>
    </div>
  );
}

export default Connect;
