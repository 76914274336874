import React from "react";
import { Link } from "react-router-dom";

function TeamCard({
  errow,
  index,
  className,
  image,
  founder,
  name,
  description,
  link,
  isTeam,
}) {
  return (
    <div className={className} key={`${index.toString()}${name}`}>
      <img
        src={image}
        style={{ borderRadius: isTeam ? "50%" : "0" }}
        width="100px"
        alt=""
        srcSet=""
      />
      <h6 className="team-founder">{founder}</h6>
      <h2 className="protocol-heading protocol-heading-font" style={{ color: "#02073e" }} >{name}</h2>
      <h5 className="partical-card-second-heading mt-0" style={{ color: "#02073e" }}>
        {errow === "true" && (
          <i
            style={{ marginRight: "20px", color: "#02073e" }}
            className="fas fa-caret-right"
          ></i>
        )}
        {description}
      </h5>
      {/* {link.map((link, i) => {
        const linkHTML = isTeam ? (
          <Link
            to={{ pathname: link?.url }}
            target="_blank"
            rel="noopener noreferrer"
          >
            <i className={`fa-lg ${link?.icon}`}></i>{" "}
          </Link>
        ) : (
          <Link className="social-icon" to={""} key={i.toString() + link}>
            {" "}
            <i className={`fa-lg ${link}`}></i>{" "}
          </Link>
        );
        return linkHTML;
      })} */}
    </div>
  );
}

export default TeamCard;
