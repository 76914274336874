import React from "react";
import { Link } from "react-router-dom";
import IMG from "../../shared/image";
import Typist from "react-typist";
import TeamCard from "../../Component/TeamCard";
import StayTouch from "../../Component/StayTouch";
import Connect from "./Connect";
import AppConstant from "../../shared/AppConstant.json";
// import { Typewriter } from 'typewriting-react';
import {
  iquantumflowsCard,
  howItWorksCard,
  teamFounder,
  teamMember,
  investCard,
} from "../../shared/String";

function Home() {
  return (
    <div className="outer-wrapper">
      <div className="iquantumflows">
        <div className="upper-container">
          <img src={IMG.logo} width="125px" alt="" srcSet="" />
          <h1 className="main-heading">{AppConstant.home.firstHeading}</h1>
          <h3 className="second-heading">
            <Typist cursor={{
              show: true,
              blink: true,
              element: '|',
              hideWhenDone: true,
              hideWhenDoneDelay: 50,
            }} >{AppConstant.home.secondHeading}</Typist>
          </h3>
          {/* <button className="doc-btn">Documentation</button>     */}
        </div>

        <h3 className="third-heading">
          {AppConstant.home.thirdHeading}
        </h3>
        <div className="lower-container">
          {iquantumflowsCard.map((elm, i) => {
            return (
              <div key={i} className="iquantumflows-card" style={elm.width ? { minWidth: elm.width } : {}}>
                <h5 className="partical-card-heading">{elm.fHeading}</h5>
                <h5 className="partical-card-second-heading">{elm.sHeading}</h5>
              </div>
            );
          })}
        </div>
      </div>
      <div className="iquantumflows-protocol row" id="about">
        <div className="col-lg-6 pt-5">
          <h2 className="protocol-heading">
            {AppConstant.home.iquantumflowsProtocol.heading}
          </h2>
          <h5 className="partical-card-second-heading" style={{ color: "#fff" }} >
            {AppConstant.home.iquantumflowsProtocol.firstDescription}
          </h5>
          <h5 className="partical-card-second-heading" style={{ color: "#fff" }}>
            {AppConstant.home.iquantumflowsProtocol.secondDescription}
          </h5>
        </div>
        <div className="col-lg-6 protocol-img">
          <img src={IMG.protocolBackImg} />
        </div>
      </div>
      <div className="iquantumflows">
        <h3 className="third-heading" style={{ margin: "0px" }}>
          {"How IQuantum Platform Works?"}
        </h3>
        <div className="lower-container">
          {howItWorksCard.map((elm, i) => {
            return (
              <div key={i} className="iquantumflows-card" style={elm.width ? { minWidth: elm.width } : {}}>
                <h5 className="partical-card-heading">{elm.fHeading}</h5>
                <h5 className="partical-card-second-heading" >{elm.sHeading}</h5>
              </div>
            );
          })}
        </div>
      </div>
      {/* <div className="iquantumflows-overview">
        <h2 className="protocol-heading protocol-heading-font">
          {AppConstant.home.iquantumflowsOverview.heading}
        </h2>
        <h5 className="partical-card-second-heading">
          {AppConstant.home.iquantumflowsOverview.firstDescription}
        </h5>
        <h5 className="partical-card-second-heading">
          {AppConstant.home.iquantumflowsOverview.secondDescription}
        </h5>
        <h5 className="partical-card-second-heading">
          {AppConstant.home.iquantumflowsOverview.thirdDescription}
        </h5>
      </div> */}

      <div className="iquantumflows-overview-after">
        <div className="wallet-support">
          <h2 className="protocol-heading protocol-heading-font">
            {AppConstant.home.unifiedDesign.heading}
          </h2>
          <h5 className="partical-card-second-heading" style={{ color: "#fff" }}>
            {AppConstant.home.unifiedDesign.firstDescription}
          </h5>
          <h5 className="partical-card-second-heading" style={{ color: "#fff" }}>
            {AppConstant.home.unifiedDesign.secondDescription}
          </h5>
          <h5 className="partical-card-second-heading" style={{ color: "#fff" }}>
            {AppConstant.home.unifiedDesign.thirdDescription}
          </h5>
          {/* <h5 className="partical-card-second-heading">
            {AppConstant.home.unifiedDesign.fourthDescription}
          </h5> */}
          {/* <div className="row mt-5">
            {walletSupportImg.map((elm, i) => {
              return (
                <div key={i} className="col-sm-6 mb-2 text-center">
                  <div className="coin-image">
                    <img src={elm.image} height="50px" alt="" srcSet="" />
                  </div>
                  <h3 className="wallet-support-third-heading">{elm.name}</h3>
                </div>
              );
            })}
          </div>
          <div className="text-center mt-2">
            <Link
              className="wallet-support-link"
              to={{
                pathname:
                  "https://www.investopedia.com/terms/d/digital-wallet.asp",
              }}
              target="_blank"
              rel="noopener noreferrer"
            >
              {AppConstant?.home?.walletSupport?.link}
            </Link>
          </div> */}
        </div>
        <div className="unified-design ">
          <h2 className="protocol-heading protocol-heading-font">
            {AppConstant.home.walletSupport.heading}
          </h2>
          <h5 className="partical-card-second-heading" style={{ color: "#fff" }}>
            {AppConstant.home.iquantumflowsProtocol.thirdDescription}
          </h5>
          <h5 className="partical-card-second-heading" style={{ color: "#fff" }}>
            <p>The Asset Classes onboarded on the Platform</p>
            <span>Real Estate Assets</span>
            <p>Private, Commercial, Farmland.</p>
            <span>Media Assets</span>
            <p>Movie Rights, Audio Rights, OTT Rights.</p>
            <span>Intangibles Assets</span>
            <p>I.P’s Fractional ownership ,Digital Arts, Patents, Collectibles, Datasets.</p>
            <span>Illiquid Assets</span>
            <p>Supply Chain, Bonds, invoices, freelancers, Social Impact.</p>
            <span>Project Financing</span>
            <p>Solar renewable projects, Car Rental Companies.</p>
          </h5>

            {/* <div style={{ marginTop: '50px' }}>
              <img src={IMG.unifiedDesign} width="100%" alt="" />
            </div> */}

        </div>
      </div>

      <div className="iquantumflows" style={{ marginTop: "0px", marginBottom: "100px" }}>
        <h3 className="third-heading" style={{ marginTop: "0px" }}>
          {"Why Invest?"}
        </h3>
        <div className="lower-container">
          {investCard.map((elm, i) => {
            return (
              <div key={i} className="iquantumflows-card" style={elm.width ? { minWidth: elm.width } : {}}>
                <h5 className="partical-card-heading">{elm.fHeading}</h5>
                <h5 className="partical-card-second-heading">{elm.sHeading}</h5>
              </div>
            );
          })}
        </div>
      </div>

      <div className="team" id="team">
        <h2 className="third-heading" style={{ margin: "0px" }}>{AppConstant.home.team}</h2>
        <div className="row mt-4">
          {teamFounder.map((elm, i) => {
            return (
              <TeamCard
                errow="true"
                index={i}
                className="team-member"
                image={elm.image}
                founder={elm.founder}
                name={elm.name}
                description={elm.describe}
                link={elm.link}
                isTeam={true}
              />
            );
          })}
        </div>
        <div className="row mt-4">
          {/* {teamMember.map((elm, i) => {
            return (
              <TeamCard
                errow="true"
                index={i}
                className="team-member"
                image={elm.image}
                name={elm.name}
                description={elm.describe}
                link={elm.link}
                isTeam={true}
              />
            );
          })} */}
        </div>

        {/* <h2
          className="protocol-heading my-5"
          style={{ color: "rgb(247, 146, 86)" }}
        >
          {AppConstant.home.advisors}
        </h2>

        <div className="row mt-4">
          {teamAdvisors.map((elm, i) => {
            return (
              <TeamCard
                errow="true"
                index={i}
                className="team-advisor"
                image={elm.image}
                name={elm.name}
                description={elm.describe}
                link={elm.link}
              />
            );
          })}
        </div> */}
      </div>
      <Connect />
      <StayTouch />
    </div>
  );
}
export default Home;
