import React from 'react';
import ColorButton from './ColorButton';
import AppConstant from "../shared/AppConstant.json";

function StayTouch() {
    return (
        <div id="contact" className="stay-touch">
                
        <img src="" alt="" srcSet="" />
            <h2 className="protocol-heading">{AppConstant.stayTouch.heading}</h2>
        <h5 className="partical-card-second-heading" style={{ color: "#fff" }} >{AppConstant.stayTouch.description}</h5>
        <div className="input-form">
            <input type="text" style={{width:"250px"}} placeholder="First Name" />
            <input type="text" style={{width:"300px"}} placeholder="Email Address" />
            <ColorButton color="rgb(247, 146, 86)" value="Subscribe" icon=""/>
        </div>
        <p className="partical-card-second-heading">{AppConstant.stayTouch.para}</p>
    </div>
    )
}

export default StayTouch
