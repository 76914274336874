import React, { useEffect, useState } from "react";
import IMG from "../shared/image";
import { NavLink, Link } from "react-router-dom";
// import { HashLink as Link } from "react-router-hash-link"
import { headerLink } from "../shared/String";

export default function HeaderComponent() {
  const [showLogo, setShowLogo] = useState(false);
  const [bgColor, setBgColor] = useState("#f3f2ff");

  const setLogo = () => {
    if (window.scrollY >= 300) {
      setShowLogo(true);
      setBgColor("#FFF")
    } else {
      setShowLogo(false);
      setBgColor("#f3f2ff")
    }
  };
  window.addEventListener("scroll", setLogo);

  const viewOnScroll = (id) => {
    const elementId = document.getElementById(id.toLowerCase());
    elementId.scrollIntoView();
  };

  useEffect(() => {
    return () => {
      window.removeEventListener("scroll", null);
    };
  }, []);

  return (
    <nav className="navbar navbar-expand-lg sticky-top navBar" style={{ background: bgColor }} >
      <div className="container-fluid">
        <button
          className="navbar-toggler navbar-dark"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="justify-content-around collapse navbar-collapse"
          id="navbarSupportedContent"
        >
          <div className={showLogo ? "header-logo-show" : "header-logo-hide"}>
            <Link id="logo" to="/home">
              {" "}
              <img
                src={IMG.logo}
                alt=""
                style={{ width: "10%", cursor: "pointer" }}
              />{" "}
            </Link>
          </div>
          <ul className="navbar-nav mb-2 mb-lg-0">
            {headerLink.map((elm, i) => {
              return (
                <li key={i} className="nav-item">
                  <NavLink
                    id={elm.name}
                    activeClassName="active"
                    className="nav-link linkColor"
                    aria-current="page"
                    onClick={() => {
                      viewOnScroll(elm.name);
                    }}
                    to={elm.link}
                  >
                    {elm.name}
                  </NavLink>
                </li>
              );
            })}
            <li className="nav-item">
              <Link
                id={"app"}
                activeClassName="active"
                className="nav-link linkColor"
                aria-current="page"
                onClick={() => {
                  window.location = "https://app.rwa.iquantumtech.com/"
                }}
                to={"investments"}
              >
                {"Investments"}
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
