import "./App.css";
import HeaderComponent from "./Component/HeaderComponent";
import FooterComponent from "./Component/FooterComponent";
import Home from "./pages/Home/home";

function App() {
  return (
    <div className="App">
      <HeaderComponent />
      <Home />
      <FooterComponent />
    </div>
  );
}

export default App;
