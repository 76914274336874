import IMG from "./image";

export const headerLink = [
  {
    name: "About",
    link: "about",
  },
  {
    name: "Team",
    link: "team",
  },
  {
    name: "Contact",
    link: "contact",
  },
];

export const iquantumflowsCard = [
  {
    fHeading: "Real World Asset Investing Accessible",
    sHeading:
      "Making the real world asset class, earlier which was only accessible to ultra high net worth individuals, family offices or funds, available for anyone to invest in starting as low as ₹5000.",
  },
  {
    fHeading: "Transparent Investments",
    sHeading:
      `We aim to provide all the financials available at a click for you to check out where your money is going & how much you are earning.
      With our interactive dashboard, access due diligence documents, calculate earnings, get cash flows and make a smart decision
      Providing credible, authentic information that
      isn't buried under too much of complex or
      technical jargon; to create a 
      clarity and approachability.`,
  },
  {
    fHeading: "Sustainable Yet Viable",
    sHeading:
      "Investments via our platform enable businesses to transition to real world assets, and providing investors with a steady income.",
  },
  {
    fHeading: "Diversified Assets",
    sHeading: `We aim to introduce new real world assets so that you can diversify your portfolio and you can spread your investments across diverse class of assets.`,
    width: "49%"
  },
  {
    fHeading: "Digitally managed underwritting",
    sHeading:
      "NBFCs/Banks/Individuals handle all the paperwork, from signing leases, to collecting payments to handling on-ground installation of projects so you don’t have to.",
      width: "49%"
  },
  {
    fHeading: "Deposit Tokens",
    sHeading:
      "Your Fractionalized NFT, which is built on the ERC-1155 standard, has the capability to store multiple ERC-20 Tokens and other NFTs within it.",
  },
  {
    fHeading: "Configure Principal",
    sHeading: "By leveraging the ERC standard, the assets deposited into your Fractionalized NFT can be time-locked.",
  },
  {
    fHeading: "Program Interest",
    sHeading:
      "Your NFT can seamlessly integrate with DeFi protocols such as Aave, MakerDAO, Lido, Alchemix, Synthetic, Uniswap, and Compound to support various assets. This means that your NFT has the ability to generate yield that can which can be directed anywhere you desire.",
  },
];

export const howItWorksCard = [
  {
    fHeading: "REGISTER AND COMPLETE YOUR E-KYC PROCESS",
    sHeading:
      "Register on our platform and instantly upload the required KYC documents to get approved and gain access to a wide range of real-world and virtual assets.",
  },
  {
    fHeading: "EXPLORE AVAILABLE ASSETS",
    sHeading:
      `Explore our diverse investment offerings, including detailed asset breakdowns and associated documents for due diligence. Calculate expected returns and make informed investment decisions at your own pace.`,
  },
  {
    fHeading: "FUND YOUR WALLET",
    sHeading:
      "Navigate to the wallet section, connect your MetaMask wallet, and add funds to cover transaction gas fees.",
  },
  {
    fHeading: "MAKE AN INVESTMENT",
    sHeading: `Choose the project you wish to invest in, enter the investment amount, review the investment breakdown, securely provide your wallet's private key, make payment via our trusted payment gateway, and receive fractionalized tokens transferred to your wallet.

    Once you invest, we will send you a debenture certificate to confirm your ownership.`,
    width: "49%"
  },
  {
    fHeading: "LET YOUR INVESTMENTS WORK FOR YOU",
    sHeading:
      `You can now sit back and enjoy the passive income generated by your investments on real world class of assets. You can easily track your returns or withdraw them as per the staking tenure by visiting the Investments section on our platform.`,
    width: "49%"
  }
];

export const investCard = [
  {
    fHeading: "Grow your wealth - Schemes Based Cashflows",
    sHeading:
      "Get a regular stream of passive income from your projects through monthly payments, which we collect on your behalf and credit directly to your wallet every month based on the schemes(equity) and asset class(Real estate).",
  },
  {
    fHeading: "Stable, Asset-backed investments",
    sHeading:
      `All investments via our platform are asset-backed investments giving investors an opportunity to invest directly in stable assets. These assets generate predictable returns and are immune to market volatility.`,
  },
  {
    fHeading: "Vetted, Digital & Data-backed investments simplified",
    sHeading:
      `We conduct comprehensive financial and technical analysis on all assets listed on our platform, and make the findings publicly available, enabling investors to make informed decisions. 
	  
	  Our team of professional contractors installs and maintains the projects, ensuring hassle-free operations.

	  We also handle all the documentation, legal work, and contract signing, so that you can simply sit back and enjoy the returns.`,
  },
  {
    fHeading: "Passive Income with a Sustainable Edge through Blockchain Technology",
    sHeading: `Our platform also exclusively lists sustainable assets, which not only generate income for you but also have a positive impact on the environment. 
	
	By investing in these assets, you can lower your carbon footprint and contribute towards a greener future. 
	
	This unique investment opportunity leverages the thriving blockchain technology to provide a seamless, secure, and transparent passive income stream with a sustainable edge.`,
    width: "49%"
  },
  {
    fHeading: "Redeem or Renew after Pre-Decided Lock-in Period",
    sHeading:
      `When making your investment, you can choose a lock-in period ranging from 6 months to 1 year. Once the lock-in period is over, you have the option to either redeem your investment through multiple flexible options provided or renew the investment for another lock-in period.`,
    width: "49%"
  }
];

export const walletSupportImg = [
  {
    image: IMG.metaMask,
    name: "MetaMask",
  },
  {
    image: IMG.fortmatic,
    name: "Fortmatic",
  },
  {
    image: IMG.authereum,
    name: "Authereum",
  },
  {
    image: IMG.walletLink,
    name: "WalletLink",
  },
  {
    image: IMG.dapper,
    name: "Dapper",
  },
  {
    image: IMG.walletConnect,
    name: "WalletConnect",
  },
];

export const teamFounder = [
  {
    image: IMG.vijay,
    // founder: "CO-FOUNDER, CEO",
    name: "Vijay Ekambaram",
    describe:
      "CO-FOUNDER, CEO",
    link: [
      {
        icon: "fab fa-linkedin",
        url: "https://www.linkedin.com/in/vijaykumare/",
      },
    ],
  },
  {
    image: IMG.devi,
    // founder: "CO-FOUNDER, CIO",
    name: "Devi Prasad Choudhury",
    describe: "CO-FOUNDER, CIO",
    link: [
      {
        icon: "fab fa-linkedin",
        url: "https://www.linkedin.com/in/vijaykumare/",
      },
      {
        icon: "fab fa-medium",
        url: "https://medium.com/@amdeviprasad/real-world-asset-backed-currency-on-blockchain-a-new-paradigm-for-trustworthy-and-secure-finance-69a93e969681",
      },
    ],
  },
  {
    image: IMG.prakash,
    // founder: "CO-FOUNDER, CTO",
    name: "Prakash Babu Shivappa",
    describe:
      "CO-FOUNDER, CTO",
    link: [
      {
        icon: "fab fa-linkedin",
        url: "https://www.linkedin.com/in/prakash-babu-shivappa-66b070b/",
      },
    ],
  },
];

export const teamMember = [
  {
    image: IMG.gaurav,
    name: "Gaurav Kumar",
    describe: "Platform Architect, AI Expert",
    link: [
      {
        icon: "fab fa-linkedin",
        url: "https://www.linkedin.com/in/gaurav-kumar-7a18688a",
      },
    ],
  },
  {
    image: IMG.kiran,
    name: "Kiran Kumar",
    describe: "Infrastructure Architect",
    link: [
      {
        icon: "fab fa-linkedin",
        url: "https://www.linkedin.com/in/kiran-kumar-hm/",
      },
    ],
  },
  {
    image: IMG.sachin,
    name: "Sachin Chaurasia",
    describe: "Enterprise Architect",
    link: [
      {
        icon: "fab fa-linkedin",
        url: "https://www.linkedin.com/in/sachin-chaurasia-5033a0116/",
      },
    ],
  },
  {
    image: IMG.shani,
    name: "Shani Tiwari",
    describe: "User Experience Manager",
    link: [
      {
        icon: "fab fa-linkedin",
        url: "https://in.linkedin.com/in/shani-tiwari",
      },
    ],
  },
  {
    image: IMG.vicky,
    name: "Vicky Chaudhary",
    describe: "Product Engineer",
    link: [
      {
        icon: "fab fa-linkedin",
        url: "https://www.linkedin.com/in/vicky-kumar-a4b35a166/",
      },
    ],
  },
  {
    image: IMG.raghav,
    name: "Raghav A",
    describe: "Blockchain Expert",
    link: [
      {
        icon: "fab fa-linkedin",
        url: "https://www.linkedin.com/in/raghav-a-63b970189/",
      },
    ],
  },
  {
    image: IMG.madhu,
    name: "Madhusudan Padmalochan",
    describe: "Devops Engineer",
    link: [
      {
        icon: "fab fa-linkedin",
        url: "https://www.linkedin.com/in/madhusudan-padmalochan-413581166/",
      },
    ],
  },
  {
    image: IMG.shivam,
    name: "Shivam Yadav",
    describe: "Operations Expert",
    link: [
      {
        icon: "fab fa-linkedin",
        url: "https://www.linkedin.com/in/shivam-yadav-b2815b170",
      },
    ],
  },
  {
    image: IMG.salman,
    name: "Salman ",
    describe: "Front-End Developer",
    link: [
      {
        icon: "fab fa-linkedin",
        url: "https://www.linkedin.com/in/mohammed-salmankhan-m-a-905744156/",
      },
    ],
  }
];

export const teamAdvisors = [
  {
    image: IMG.robSecord,
    name: "Tugrul Firatli",
    describe: "Founder & CEO of BCWare",
    link: ["fab fa-linkedin"],
  },
  {
    image: IMG.robSecord,
    name: "Jitesh Patel",
    describe: "Director at BCWare",
    link: ["fab fa-linkedin"],
  },
];

export const learnCard = [
  {
    image: "fab fa-discord",
    name: "Chat",
    describe: "Chat in real-time with over 3,300 IQuantum community members.",
  },
  {
    image: "far fa-file-alt",
    name: "Learn",
    describe:
      "Explore a high-level overview of the protocol and its existing components.",
  },
  {
    image: "fab fa-twitter",
    name: "Follow",
    describe: "Follow @DefiNft for the latest updates and news.",
  },
];

export const investor = [
  IMG.theLao,
  IMG.coingecko,
  IMG.longhash,
  IMG.moonwhale,
  IMG.parafi,
  IMG.ventures,
  IMG.fourRevolution,
  IMG.au21Capital,
  IMG.hashkey,
];

export const angelInvestors = [
  {
    image: "",
    name: "Leighton Cusak",
    describe: "PoolTogether",
  },
  {
    image: "",
    name: "Tyler Ward",
    describe: "BarnBridge",
  },
  {
    image: "",
    name: "Stani Kulechov",
    describe: "Aave",
  },
  {
    image: "",
    name: "Liz Rabban",
    describe: "DriveWealth",
  },
  {
    image: "",
    name: "Matt Ferrick",
    describe: "Nifty Gateway",
  },
  {
    image: "",
    name: "Jordan Momtazi",
    describe: "Synthetix",
  },
  {
    image: "",
    name: "Kain Warwick",
    describe: "Synthetix",
  },
  {
    image: "",
    name: "Michael Miglio",
    describe: "Bridge Mutual",
  },
];

export const footerLink = [
  {
    name: "Privacy Policy",
    link: "",
  },
  {
    name: "Terms of Use",
    link: "",
  },
  {
    name: "Beta End User License Agreement",
    link: "",
  },
  {
    name: "Legal",
    link: "",
  },
  {
    name: "FAQ",
    link: "",
  },
];

export const footerSocialLink = [
  {
    name: "fab fa-discord",
    link: "",
  },
  {
    name: "fab fa-medium-m",
    link: "",
  },
  {
    name: "fab fa-twitter",
    link: "",
  },
  {
    name: "fab fa-instagram",
    link: "",
  },
];

// "connect": {
//     "heading": "Connect & Learn",
//     "description": "Learn more about IQuantum, chat with our team, and our growing community.",
//     "backedBy": "Backed By",
//     "investors": "Investors",
//     "angelInvestors": "Angel Investors",
//     "secondDescription": "Interested in learning more about investing opportunities with IQuantum?"
// }
